<template>
  <InventarioGeneral
    titulo="Reportes de Inventario - Inventario General"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    tipoTransaccion="compra"
  />
</template>

<script>
import InventarioGeneral from "../components/InventarioGeneral.vue";
import ReporteInventarioService from "../services";

export default {
  name: "ReporteComprasTotalesMensualesLayout",
  components: {
    InventarioGeneral,
  },
  data() {
    return {};
  },
  methods: {
    servicioGetReporte:
      ReporteInventarioService.getReporteInventarioInventarioGeneral,
    servicioGetReportePdf:
      ReporteInventarioService.getReporteInventarioInventarioGeneralPdf,
  },
};
</script>