<template>
  <div>
    <b-overlay :show="loading">
      <ag-grid-vue
        :gridOptions="gridOptions"
        class="ag-theme-balham ag-grid-table mb-1"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        @grid-ready="onGridReady"
        :rowData="lista"
        rowSelection="single"
        colResizeDefault="shift"
        :animateRows="false"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowClassRules="rowClassRules"
        @first-data-rendered="onFirstDataRendered"
        :alwaysShowVerticalScroll="true"
        :suppressPaginationPanel="true"
        :pinnedBottomRowData="pinnedBottomRowData"
      >
      </ag-grid-vue>
    </b-overlay>
    <b-card-text>
      <b-row>
        <b-col cols="6" md="6">
          <span class="text-nowrap text-bold">
            <strong>
              Mostrando
              {{ currentPage * paginationPageSize - (paginationPageSize - 1) }}
              -
              {{
                lista.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : lista.length
              }}
              de {{ lista.length }} Registros
            </strong>
          </span>
        </b-col>
        <b-col cols="6" md="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalPages"
            :per-page="1"
            align="right"
            size="sm"
            class="my-0"
          >
            <template #prev-text>
              <feather-icon size="18" icon="ChevronLeftIcon" />
            </template>
            <template #next-text>
              <feather-icon size="18" icon="ChevronRightIcon" />
            </template>
            <template #last-text>
              <feather-icon size="18" icon="ChevronsRightIcon" />
            </template>
            <template #first-text>
              <feather-icon size="18" icon="ChevronsLeftIcon" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>
    
  <script>
import ListadoReportesMixin from "../../mixins/ListadoReportesMixin";
export default {
  mixins: [ListadoReportesMixin],
  name: "ListadoInventario",
  data() {
    return {
      columnDefs: [
        {
          headerName: "CODIGO",
          field: "codigo",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "LINEA-MARCA",
          field: "marca.descripcion",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "CATEGORIA",
          field: "categoria.descripcion",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "UNIDAD",
          field: "unidad.descripcion",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "DESCRIPCION",
          field: "descripcion",
          filter: false,
          minWidth: 200,
        },
        {
          headerName: "DETALLE",
          headerComponentParams: {
            /*html */
            template: `<table width="100%" style="border-collapse">
                  <tr>
                      <th style="width: 40px; text-align: right;">CANTIDAD</th>
                      <th style="width: 100px; text-align: right;">COSTO</th>
                      <th style="width: 100px; text-align: right;">SUBTOTAL</th>
                  </tr>
              </table>`,
          },
          cellRenderer: function (param) {
            if (param.node.rowPinned) {
              return undefined;
            }
            {
              let detalle = /*html*/ `<table width="100%" style="border-collapse: collapse;">`;
              let totalCantidad = 0;
              let total = 0;
              for (
                let index = 0;
                index < param.data.productos_detalles.length;
                index++
              ) {
                const cantidad = param.data.productos_detalles[index].cantidad;
                const costo = param.data.productos_detalles[index].costo;
                const subtotal = cantidad * costo;
                totalCantidad += cantidad;
                total += subtotal;
                detalle +=
                  /*html */
                  `<tr>
                      <td style="width: 40px; text-align: right;">${cantidad.toFixed(
                        2
                      )}</td>
                      <td style="width: 100px; text-align: right;">${Number(
                        costo
                      ).toFixed(2)}</td>
                      <td style="width: 100px; text-align: right;">${subtotal.toFixed(
                        2
                      )}</td>
                  </tr>`;
              }
              detalle += /*html */ `
                <tr>
                    <td style="width: 40px; text-align: right; border-top: 1px solid #b8c2cc;">${totalCantidad.toFixed(
                      2
                    )}</td>
                    <td style="width: 100px; text-align: right; border-top: 1px solid #b8c2cc;"></td>
                    <td style="width: 100px; text-align: right; border-top: 1px solid #b8c2cc;">${total.toFixed(
                      2
                    )}</td>
                </tr>
              </table>`;
              return detalle;
            }
          },
          autoHeight: true,
          suppressSizeToFit: true,
          type: "numericColumn",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "CANTIDAD TOTAL",
          type: "numericColumn",
          valueFormatter: numberFormatter,
          field: "cantidad_total",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "COSTO TOTAL",
          type: "numericColumn",
          valueFormatter: numberFormatter,
          field: "costo_total",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
      ],
    };
  },
  computed: {
    pinnedBottomRowData() {
      return [
        {
          cantidad_total: "",
          costo_total: this.total,
        },
      ];
    },
    lista() {
      return this.items && this.items[0] ? this.items[0] : [];
    },
    total() {
      return this.items && this.items[1] ? this.items[1].total : 0;
    },
  },
  methods: {},
};
function numberFormatter(params) {
  if (params.value == "") return "";
  return Number(params.value).toFixed(2);
}
</script>
  <style lang="scss">
@import "@/assets/scss/components/agGridStyle.scss";
</style>
    