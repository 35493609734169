<template>
  <div>
    <b-card :title="titulo">
      <b-card-text>
        <b-row>
          <b-col cols="12" md="4" xl="6">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="ml-1"
              @click="getReporte"
            >
              <span class="d-none d-lg-block">
                <feather-icon icon="RefreshCwIcon" class="mr-50" />
                <span class="align-middle">REFRESCAR</span>
              </span>
              <feather-icon icon="RefreshCwIcon" class="d-lg-none" size="14" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              size="sm"
              class="ml-1"
              @click="mostrarPdf"
            >
              <span class="d-none d-lg-block">
                <feather-icon icon="FileIcon" class="mr-50" />
                <span class="align-middle">VISUALIZAR EN PDF</span>
              </span>
              <feather-icon icon="FileIcon" class="d-lg-none" size="14" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-text>

      <b-card-text>
        <ListadoInventario ref="listado" :items="items" :loading="loading" />
      </b-card-text>
    </b-card>

    <ModalMostrarPdf
      :titulo="titulo"
      :pdf="pdf"
      :pdfBase64="pdfBase64"
      :mostrarModal="mostarModal"
      @cerrarModal="cerrarModal"
    />
  </div>
</template>

<script>
import ReportesMixin from "../../mixins/ReportesMixin";
import {
  BCard,
  BCardText,
  BCardTitle,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import ListadoInventario from "../views/ListadoInventario.vue";

export default {
  name: "ReporteComprasTotalesMensuales",
  mixins: [ReportesMixin],
  components: {
    BCard,
    BCardText,
    BCardTitle,
    BRow,
    BCol,
    BButton,
    ListadoInventario,
  },
  created() {
    this.getReporte();
  },
  data() {
    return {};
  },
  methods: {},
};
</script>